.numberCard__container {
    padding: 0.5rem 0;
}

.numberCard__card-container {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}