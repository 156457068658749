.addTaskInput input {
    border-radius: 20px;
}

.addTaskInput .MuiOutlinedInput-root {
    padding: 0.5rem;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .02), 0 2px 18px rgba(0, 0, 0, .05) !important;
}

.addTaskInput .rightIcon svg {
    margin-right: 1rem !important;
}

.addTaskInput .leftIcon svg {
    margin-left: 1rem !important;
}

.addTaskInput button:hover {
    background-color: transparent;
}