body {
    margin: 0;
    padding: 0;
}

.MuiBox-root main {
    padding: 0;
    max-width: 100vw !important;
}

.app {
    height: 80vh;
}

.md-card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
}

.MuiCard-root {
    box-shadow: 0 1px 4px rgba(0, 0, 0, .02), 0 2px 18px rgba(0, 0, 0, .05) !important;
}

.MuiDrawer-root .MuiDrawer-paper {
    border-right: 0 !important;
}

.MuiDrawer-root .MuiDrawer-paper .MuiButtonBase-root {
    border-radius: 0 25px 25px 0 !important;
}