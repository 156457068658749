.home__add-task {
    display: flex;
}

.home__add-task button {
    margin-left: 1rem;
    padding: 0 1.5rem;
}

.home__date-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.home__date-container .day {
    text-transform: uppercase;
    color: #ababab;
}

.home__date-container .date {
    font-size: 1.8rem;
    font-weight: 700;
}

.home__date-container .month {
    font-size: 1.8rem;
    font-weight: 700;
}

.home__list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.home__list-container .title {
    font-size: 1.8rem;
    font-weight: 700;
}

.home__list-container .number {
    font-weight: 300;
}

.home__list-container .text {
    font-size: 0.8rem !important;
    text-transform: uppercase;
    color: #ababab;
}

.home__list-container.mobile .text {
    margin-top: 2px;
    font-size: 0.6rem !important;
}