.main__container {
    box-shadow: none !important;
}

.main__title {
    margin-top: 1rem;
    text-align: center;
    font-size: 3.2rem;
    font-weight: 900;
}

.main__container .MuiButtonBase-root {
    border: 1px solid #20A4F3;
    border-radius: 50px;
    margin: 0 0.5rem;
    padding: 0.75rem 1.25rem;
}

.main__heading-container {
    margin-top: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main__heading-container .subtitle {
    margin-top: 1.5rem;
    text-align: center;
    font-size: 1.6rem;
    font-weight: 300;
    color: #ababab;
}

.get-started {
    margin-top: 2rem !important;
    background-color: #20A4F3 !important;
    padding: 1rem 2rem !important;
    border-radius: 50px !important;
    box-shadow: none !important;
}

.get-started:hover {
    background-color: #20A4F3 !important;
}

.main__feature-container {
    margin-top: 0.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main__feature-container .title {
    margin: 1rem 0;
    text-align: center;
    font-size: 3.2rem;
    font-weight: 900;
}

.feature-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.75rem;
}

.feature-card .feature-title {
    font-size: 1.75rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0.75rem;
}

.feature-card .feature-subtitle {
    font-size: 1.2rem;
    font-weight: 400;
    text-align: center;
    color: #ababab;
    margin-bottom: 0.75rem;
}

@media (max-width: 768px) {
    .feature-card .feature-title {
        font-size: 1rem;
        /* Reduce font size for mobile */
    }

    .feature-card .feature-subtitle {
        font-size: 0.8rem;
        /* Reduce font size for mobile */
    }
}

.main__join-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
}

.beta-section {
    width: 100%;
    text-align: center;
    background-color: #f8f8f8;
    padding: 2rem 3rem;
    margin: 1.75rem 0;
    border-radius: 25px;
}

.beta-section span {
    font-size: 1.8rem;
}

.made-in-india {
    font-size: 1.2rem;
}