.footer__container {
    background-color: #fff;
    padding: 1rem 0;
    position: fixed;
    bottom: 0;
    width: 80vw;
}

.footer__container p {
    font-size: 0.85rem;
}